import React from "react";
import { Link, useOutletContext } from "react-router-dom";
import create_cv from "../../images/home/create_cv.webp";
import increase_chance from "../../images/home/increase_chance.webp";
import edit_cv from "../../images/home/edit_cv.webp";

const Home = () => {
  const { translations } = useOutletContext();

  return (
    <div className="pt-5">
      <div className="d-flex flex-col gap-3 justify-center items-center mx-auto h-full py-5">
        <h1 className="text-xl md:text-4xl font-semibold text-white text-center">
          {translations?.home?.create_professional_cv}
        </h1>
        <p className="text-white text-center w-[90%]">
          {translations?.home?.prepare_cv}
        </p>
        <Link
          to="/login"
          className="text-white bg-[#01EEFE] p-3 rounded-md font-semibold hover:bg-[#373d94] outline-none border-none"
        >
          {translations?.home?.create_cv_button}
        </Link>
        <p className="text-white">{translations?.home?.increase_chance}</p>
        <div className="flex flex-col gap-3 lg:w-[50vw] w-[80vw]">
          <div className="shadow-lg lg:h-[250px] xl:h-[180px] w-full bg-white flex flex-col sm:flex-row items-center justify-between p-5 gap-5 rounded-md">
            <img
              src={create_cv}
              alt="quick"
              className="w-20 h-20 rounded-full"
            />
            <div className="">
              <h2 className="font-semibold">
                {translations?.home?.quick_easy_title}
              </h2>
              <p className="text-[#999898] text-sm leading-6 mt-2">
                {translations?.home?.quick_easy_description}
              </p>
            </div>
          </div>
          <div className="shadow-lg lg:h-[250px] xl:h-[180px] w-full bg-white flex flex-col sm:flex-row items-center justify-between p-5 gap-5 rounded-md">
            <img
              src={increase_chance}
              alt="quick"
              className="w-20 h-20 rounded-full"
            />
            <div>
              <h2 className="font-semibold">
                {translations?.home?.increased_chance_title}
              </h2>
              <p className="text-[#999898] text-sm leading-6 mt-2 ">
                {translations?.home?.increased_chance_description}
              </p>
            </div>
          </div>
          <div className="shadow-lg lg:h-[250px] xl:h-[180px] w-full bg-white flex flex-col sm:flex-row items-center justify-between p-5 gap-5 rounded-md">
            <img src={edit_cv} alt="quick" className="w-20 h-20 rounded-full" />
            <div>
              <h2 className="font-semibold">
                {translations?.home?.edit_applications_title}
              </h2>
              <p className="text-[#999898] text-sm leading-6 mt-2">
                {translations?.home?.edit_applications_description}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
