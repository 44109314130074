import React, { useCallback } from "react";
import useAxios from "../../../utils/useAxios";

const Header = () => {
  const [profile, setProfile] = React.useState([]);

  const axiosInstance = useAxios();

  const fetchProfil = useCallback(async () => {
    try {
      const { data } = await axiosInstance.get(`/profile`);

      setProfile(data);
    } catch (error) {
      console.log(error);
    }
  }, []);

  React.useEffect(() => {
    fetchProfil();
  }, [fetchProfil]);

  return profile.length > 0 ? (
    <div className="">
      {profile.map((item, index) => (
        <div key={index} className="flex flex-col gap-2 ">
          <h1 className="text-3xl font-semibold break-words w-full">
            {item.fullname}
          </h1>
          <h2 className="text-lg font-semibold break-words w-full">
            {item.title}
          </h2>
          <p className="text-xs text-left break-words w-full">
            {item.explanation}
          </p>
        </div>
      ))}
    </div>
  ) : null;
};

export default Header;
