import React, { useEffect, useState } from "react";
import { CV } from "../../CV";
import { VscOpenPreview } from "react-icons/vsc";
import { SlClose } from "react-icons/sl";
import {
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from "react-icons/md";
import { useNavigate, useOutletContext } from "react-router-dom";
import WorkExperience from "./WorkExperience";
import Profil from "./Profil";
import Education from "./Education";

const Experiences = () => {
  const [showCV, setShowCV] = useState(false);
  const navigate = useNavigate();
  const { translations } = useOutletContext();
  const modalRef = React.useRef();

  useEffect(() => {
    if (showCV) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [showCV]);

  const experienceItems = [<Profil />, <WorkExperience />, <Education />];

  const handleShowCV = () => {
    setShowCV(true);
  };

  const handleCloseCV = () => {
    setShowCV(false);
  };

  const handlePrev = () => {
    navigate("/interests-skills");
  };

  const handleNext = () => {
    navigate("/resume");
  };

  const handleOutsideClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      handleCloseCV();
    }
  };

  return (
    <div className="flex flex-col lg:flex-row items-center justify-between py-5 min-h-screen">
      <div className="pt-[50px] flex flex-col gap-4 items-center justify-center w-full">
        <div className="flex justify-between items-center w-[300px] md:w-[500px]">
          <h1 className="text-white text-3xl">
            {translations?.experiences?.title}
          </h1>
          <VscOpenPreview
            onClick={handleShowCV}
            className="text-xl cursor-pointer text-white"
          />
        </div>

        <div className="flex flex-col gap-3 text-[#4C4C4C]">
          {experienceItems.map((item, index) => (
            <div key={index}>{item}</div>
          ))}
        </div>
        <div className="text-white flex justify-between w-[300px] md:w-[500px]">
          <div
            className="flex items-center gap-1 bg-[#F5A50C] rounded-3xl w-20 h-8 justify-center cursor-pointer"
            onClick={handlePrev}
          >
            <MdKeyboardDoubleArrowLeft className="bg-white rounded-full text-[#F5A50C]" />
            <span>{translations?.experiences?.navigate_back}</span>
          </div>
          <div
            className="flex items-center gap-1 bg-[#1CA57C] rounded-3xl w-20 h-8 justify-center cursor-pointer"
            onClick={handleNext}
          >
            <span>{translations?.experiences?.navigate_next}</span>
            <MdKeyboardDoubleArrowRight className="bg-white rounded-full text-[#1CA57C]" />
          </div>
        </div>
      </div>
      {showCV ? (
        <div className="bg-black bg-opacity-75 absolute top-0 left-0 w-full min-h-screen flex justify-center items-center">
          <div ref={modalRef}>
            <CV />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Experiences;
