import React from "react";
import { FaDownload } from "react-icons/fa6";
import { useOutletContext } from "react-router-dom";

const Download = ({ generatePDF }) => {
  const { translations } = useOutletContext();
  return (
    <div
      onClick={generatePDF}
      className={`flex items-center justify-center gap-3  text-lg text-white w-[190px] h-[40px] bg-orange-600 rounded-md cursor-pointer`}
    >
      <FaDownload />
      <span>{translations?.download_cv}</span>
    </div>
  );
};

export default Download;
