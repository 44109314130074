import React from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { FaRegCheckCircle } from "react-icons/fa";

const RegisterModal = ({ setShow, show, email }) => {
  const handleClose = () => setShow(false);
  return (
    <Modal
      show={show}
      onHide={handleClose}
      className="flex justify-center items-center"
    >
      <Modal.Body className="text-center">
        <FaRegCheckCircle className="text-green-900 text-5xl mx-auto m-2" />
        <h2 className="text-xl font-bold mb-4">Registration Successful</h2>
        <p className="mb-4">Thank you for registering!</p>
        <p className="mb-4 text-sm text-green-900 bg-[#DFF0D7] p-3 rounded-md">
          A verification email has been sent to{" "}
          <strong className="text-[#01EEFE]">{email}</strong>. Please verify
          your email within 5 minutes. If you do not verify, your registration
          will be canceled and the link will become invalid.
        </p>
        <Link
          to="/login"
          className="text-white bg-[#01EEFE] px-5 py-2 rounded-md font-semibold"
        >
          OK
        </Link>
      </Modal.Body>
    </Modal>
  );
};

export default RegisterModal;
