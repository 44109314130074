import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SaveBtn from "../../../components/buttons/SaveBtn";
import AddBtn from "../../../components/buttons/AddBtn";
import { MdDelete, MdEdit } from "react-icons/md";
import useAxios from "../../../utils/useAxios";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { useOutletContext } from "react-router-dom";

const WorkExperience = ({ onMoveUp, onMoveDown }) => {
  const { userId } = useSelector((state) => state.auth);

  const initialState = {
    userId: userId,
    companyName: "",
    position: "",
    description: "",
    startDate: "",
    endDate: "",
  };

  const [workExperience, setWorkExperience] = useState([]);
  const axiosInstance = useAxios();
  const { translations } = useOutletContext();

  const fetchWorkExperience = useCallback(async () => {
    try {
      const { data } = await axiosInstance.get(`/workexperience`);
      setWorkExperience(data);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    fetchWorkExperience();
  }, [fetchWorkExperience]);

  const [formData, setFormData] = useState(initialState);
  const [expand, setExpand] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [edit, setEdit] = useState(false);

  const formElements = [
    {
      name: "companyName",
      placeholder: translations?.work_experience?.company,
    },
    {
      name: "position",
      placeholder: translations?.work_experience?.position,
    },
    {
      name: "description",
      placeholder: translations?.work_experience?.description,
    },
    {
      name: "startDate",
      placeholder: translations?.work_experience?.start_date,
    },
    {
      name: "endDate",
      placeholder: translations?.work_experience?.end_date,
    },
  ];

  const handleExpand = () => {
    setExpand(!expand);
  };

  const handleChange = async (e) => {
    const newFormData = { ...formData, [e.target.name]: e.target.value };
    setFormData(newFormData);
  };

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      if (edit === false) {
        await axiosInstance.post(`/workexperience`, formData);
      } else {
        await axiosInstance.put(`/workexperience/${formData.id}`, formData);
        setEdit(false);
      }
      fetchWorkExperience();
      setFormData(initialState);
      setShowForm(false);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteWorkExperience = async (id) => {
    try {
      await axiosInstance.delete(`/workexperience/${id}`);
      fetchWorkExperience();
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditBtn = (row, index) => {
    setEdit(index);
    setShowForm(true);
    setFormData(row);
  };

  return (
    <div className="bg-white w-[300px] md:w-[500px] mx-auto flex flex-col gap-3 p-4 rounded-md shadow">
      <div className="flex justify-between items-center">
        <h1 onClick={handleExpand} className="flex-1 cursor-pointer">
          {translations?.work_experience?.title}
        </h1>
        <div className="flex gap-2 text-xl">
          <IoIosArrowUp onClick={onMoveUp} className="cursor-pointer" />
          <IoIosArrowDown onClick={onMoveDown} className="cursor-pointer" />
        </div>
      </div>
      {expand ? (
        <>
          <hr />
          {workExperience.map((data, index) => (
            <div
              key={index}
              className="flex justify-between items-start text-xs"
            >
              <div className="w-96">
                <div className="w-full flex gap-1">
                  {" "}
                  <h1 className="break-words max-w-44">{data.companyName}</h1>
                  {data.position && <span className="w-2">-</span>}
                  <p className="break-words w-44">{data.position}</p>
                </div>
              </div>
              <div className="flex gap-3 w-12">
                <MdEdit
                  className="cursor-pointer"
                  onClick={() => handleEditBtn(data, index)}
                  size={15}
                />
                <MdDelete
                  className="cursor-pointer"
                  onClick={() => deleteWorkExperience(data.id)}
                  size={15}
                />
              </div>
            </div>
          ))}

          <>
            {showForm ? (
              <form
                className="flex flex-wrap justify-between gap-3"
                onSubmit={handleSave}
              >
                {formElements.map((element, index) => (
                  <input
                    key={index}
                    type="text"
                    placeholder={element.placeholder}
                    className="border-1  border-[#999898] w-full h-[40px] p-2 outline-none rounded-md"
                    name={element.name}
                    value={formData[element.name]}
                    onChange={handleChange}
                  />
                ))}

                <div className="flex justify-end w-full">
                  <SaveBtn />
                </div>
              </form>
            ) : null}
            <AddBtn setShowForm={setShowForm} />
          </>
        </>
      ) : null}
    </div>
  );
};

export default WorkExperience;
