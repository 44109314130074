import React, { useState, useEffect } from "react";
import useAxios from "../../../utils/useAxios";
import { useSelector } from "react-redux";
import Cropper from "react-easy-crop";
import { getCroppedImg } from "../../../utils/cropImage"; // Doğru import
import { GrPowerReset } from "react-icons/gr";
import { MdPhotoCamera } from "react-icons/md";
import { useSnackbar } from "notistack";
import { useOutletContext } from "react-router-dom";

const Upload = () => {
  const [preview, setPreview] = useState(null);
  const [croppedArea, setCroppedArea] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [existingImage, setExistingImage] = useState(null); // Yeni state
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const axiosInstance = useAxios();
  const { userId } = useSelector((state) => state.auth);
  const { enqueueSnackbar } = useSnackbar();
  const { translations } = useOutletContext();

  const apiURL = process.env.REACT_APP_FILE_API_URL || "http://localhost:3030"; // API URL

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const { data } = await axiosInstance.get(`/images/${userId}`);
        if (data.length > 0) {
          setExistingImage(data[0].path);
          setCroppedImage(data[0].path);
        }
      } catch (err) {
        console.error(err);
      }
    };

    fetchImage();
  }, [userId, axiosInstance]);

  const handleFileChange = (e) => {
    setPreview(URL.createObjectURL(e.target.files[0]));
  };

  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };

  const showCroppedImage = async () => {
    try {
      const croppedImageBlob = await getCroppedImg(preview, croppedArea);
      const croppedImageUrl = URL.createObjectURL(croppedImageBlob);
      setCroppedImage(croppedImageUrl);
      handleUpload(croppedImageBlob);
    } catch (e) {
      console.error(e);
    }
  };

  const handleUpload = async (croppedImageBlob) => {
    const formData = new FormData();
    formData.append("file", croppedImageBlob, "cropped.jpg");
    formData.append("userId", userId);

    try {
      const method = existingImage ? "put" : "post";
      const { data } = await axiosInstance[method]("/upload", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      enqueueSnackbar("File uploaded successfully", { variant: "success" });
      console.log(data, "data");
      setExistingImage(data.image.path); // Yüklenen resmi state'e kaydet
      setPreview(null); // Önizlemeyi temizle
      setCroppedArea(null); // Kırpma alanını temizle
    } catch (err) {
      console.error(err);
      enqueueSnackbar("File upload failed", { variant: "error" });
    }
  };

  const handleReset = async () => {
    try {
      await axiosInstance.delete(`/upload/${userId}`);
      setPreview(null);
      setCroppedArea(null);
      setCroppedImage(null);
      setExistingImage(null); // Yeni state reset
    } catch (err) {
      console.error(err);
    }
  };

  const handleImageClick = () => {
    setCroppedImage(null);
    setPreview(null);
  };

  const handleCancel = () => {
    setPreview(null);
    setCroppedArea(null);
  };

  return (
    <div className="flex flex-col items-center">
      {!croppedImage && !existingImage && (
        <>
          <input
            id="fileInput"
            type="file"
            onChange={handleFileChange}
            className="hidden"
          />
          <label
            htmlFor="fileInput"
            className="my-4 px-4 py-2 w-28 h-28 text-3xl flex items-center justify-center bg-gray-300 text-white rounded cursor-pointer"
          >
            <MdPhotoCamera />
          </label>
        </>
      )}
      {preview && !croppedImage && (
        <div className="absolute top-0 left-0 w-full h-full bg-black flex items-center justify-center">
          <div className="relative w-full h-80">
            <Cropper
              image={preview}
              crop={crop}
              zoom={zoom}
              aspect={1}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
            />
            <div className="absolute -bottom-12 left-1/2 transform -translate-x-1/2 flex space-x-4">
              <button
                onClick={showCroppedImage}
                className="px-4 py-2 text-xs bg-blue-500 text-white rounded hover:bg-blue-700"
              >
                {translations?.photo?.upload}
              </button>
              <button
                onClick={handleCancel}
                className="px-4 py-2 text-xs bg-red-500 text-white rounded hover:bg-red-700"
              >
                {translations?.photo?.cancel}
              </button>
            </div>
          </div>
        </div>
      )}
      {(croppedImage || existingImage) && !preview && (
        <div className="relative flex flex-col items-center gap-2">
          <img
            src={`${apiURL}${existingImage}`}
            alt="Uploaded"
            className="mt-4 border rounded cursor-pointer"
            width="100"
            onClick={handleImageClick}
          />
          <GrPowerReset
            onClick={handleReset}
            className="cursor-pointer mb-3"
            title="reset"
          />
        </div>
      )}
    </div>
  );
};

export default Upload;
