// src/components/ForgotPassword.js
import React, { useState } from "react";
import axios from "axios";
import { useSnackbar } from "notistack";
import { Link, useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/forgot-password`, {
        email,
      });
      enqueueSnackbar("Password reset link sent to your email", {
        variant: "success",
      });
      navigate("/login");
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Failed to send password reset link", {
        variant: "error",
      });
    }
  };

  return (
    <div className="bg-[#20242D] w-full h-screen flex justify-center items-center">
      <div className="flex flex-col gap-3 items-center">
        <div className="w-[350px] text-white text-center">
          Enter your email to reset your password
        </div>
        <form
          onSubmit={handleSubmit}
          className="flex flex-col items-center gap-3"
        >
          <input
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            placeholder="Email Address"
            className="ps-3 bg-[#444446] w-[350px] h-[40px] rounded-lg text-white outline-none placeholder:text-[#999898]"
          />
          <button
            type="submit"
            className="w-[350px] h-[40px] border-2 border-[#01EEFE] text-[#01EEFE] rounded-lg hover:bg-[#01EEFE] hover:text-white"
          >
            Send Reset Link
          </button>
        </form>
        <div className="text-sm flex gap-3">
          <span className="text-[#999898]">Remembered your password?</span>
          <Link className="text-[#01EEFE] cursor-pointer" to="/login">
            Login
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
