import React from "react";
import Header from "./Header";
import WorkExperience from "./WorkExperience";
import Education from "./Education";

const Right = () => {
  return (
    <div className="col-span-5 p-3 flex flex-col gap-3 text-slate-700">
      <Header />
      <WorkExperience />
      <Education />
    </div>
  );
};

export default Right;
