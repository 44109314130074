import React from "react";
import { IoMdAddCircleOutline } from "react-icons/io";
import { useOutletContext } from "react-router-dom";

const AddBtn = ({ setShowForm }) => {
  const handleAddBtn = () => {
    setShowForm(true);
  };

  const { translations } = useOutletContext();

  return (
    <div
      className="bg-slate-200 h-[40px] flex items-center justify-center rounded-md cursor-pointer"
      onClick={handleAddBtn}
    >
      <IoMdAddCircleOutline size={25} />
      <span>{translations?.add_field}</span>
    </div>
  );
};

export default AddBtn;
