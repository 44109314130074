import React from "react";
import { FaSave } from "react-icons/fa";
import { useOutletContext } from "react-router-dom";

const SaveBtn = () => {
  const { translations } = useOutletContext();
  return (
    <button
      type="submit"
      className={`border-1 border-[#4C4C4C] ${
        translations?.save === "Speichern" ? "w-[100px]" : "w-[60px]"
      } h-[30px]  rounded-md flex text-sm items-center justify-center gap-1 cursor-pointer bg-[#4C4C4C] text-white`}
    >
      <FaSave />
      {translations?.save}
    </button>
  );
};

export default SaveBtn;
