import React, { useCallback, useEffect, useRef, useState } from "react";
import PhotoUpload from "./PhotoUpload";
import { useNavigate, useOutletContext } from "react-router-dom";
import { CV } from "../../CV";
import { useSelector } from "react-redux";
import useAxios from "../../../utils/useAxios";
import { VscOpenPreview } from "react-icons/vsc";

const PersonalInfo = () => {
  const { userId } = useSelector((state) => state.auth);
  const { translations } = useOutletContext();

  const initialState = {
    userId: userId,
    name: "",
    surname: "",
    email: "",
    phone: "",
    street: "",
    postalCode: "",
    city: "",
    country: "",
    driversLicense: "",
    maritalStatus: "",
    dateofbirth: "",
    placeofbirth: "",
    gender: "",
    website: "",
    photo: "",
  };

  const basicFormElements = [
    { name: "name", placeholder: translations?.personal_info?.first_name },
    { name: "surname", placeholder: translations?.personal_info?.last_name },
    { name: "email", placeholder: translations?.personal_info?.email },
    { name: "phone", placeholder: translations?.personal_info?.phone },
    { name: "street", placeholder: translations?.personal_info?.address },
    { name: "postalCode", placeholder: translations?.personal_info?.zip_code },
    { name: "city", placeholder: translations?.personal_info?.city },
    { name: "country", placeholder: translations?.personal_info?.country },
  ];

  const additionalFormElements = [
    {
      name: "driversLicense",
      placeholder: translations?.personal_info?.driver_license,
    },
    {
      name: "maritalStatus",
      placeholder: translations?.personal_info?.marital_status,
    },
    {
      name: "dateofbirth",
      placeholder: translations?.personal_info?.date_of_birth,
    },
    {
      name: "placeofbirth",
      placeholder: translations?.personal_info?.place_of_birth,
    },
    {
      name: "gender",
      placeholder: translations?.personal_info?.gender,
    },
    {
      name: "website",
      placeholder: translations?.personal_info?.website,
    },
  ];

  const axiosInstance = useAxios();
  const navigate = useNavigate();
  const [formData, setFormData] = useState(initialState);
  const [existData, setExistData] = useState([]);
  const [showAdditionalFields, setShowAdditionalFields] = useState(false);
  const [showCVModal, setShowCVModal] = useState(false);
  const modalRef = useRef(null);

  const fetchPersonalInfo = useCallback(async () => {
    try {
      const { data } = await axiosInstance.get(`/personalinfo`);
      setExistData(data);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    fetchPersonalInfo();
  }, [fetchPersonalInfo]);

  useEffect(() => {
    if (existData.length > 0) {
      const modifiedData = existData
        .filter((item) => item.userId === userId)
        .map((item) => ({
          ...item,
          dateofbirth: item.dateofbirth?.split("T")[0],
        }))[0];
      if (modifiedData) {
        setFormData(modifiedData);
      }
    }
  }, [existData, userId]);

  useEffect(() => {
    if (showCVModal) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [showCVModal]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (existData.length > 0) {
        await axiosInstance.put(`/personalinfo/${existData[0].id}`, formData);
      } else {
        await axiosInstance.post(`/personalinfo`, formData);
      }

      navigate("/interests-skills");
    } catch (error) {
      console.log(error);
    }
  };

  const toggleAdditionalFields = () => {
    setShowAdditionalFields(!showAdditionalFields);
  };

  const handleShowCV = () => {
    setShowCVModal(true);
  };

  const handleCloseCV = () => {
    setShowCVModal(false);
  };

  if (!translations) {
    return <div>Loading...</div>; // Translations yüklenene kadar gösterilecek yükleme durumu
  }

  const handleOutsideClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      handleCloseCV();
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen">
      <div className="pt-[100px] flex flex-col gap-4 items-center justify-center w-full min-h-screen">
        <div className="bg-white w-[300px] md:w-[700px] rounded-md p-5 flex flex-col gap-1 justify-center items-center">
          <div className="flex justify-between w-full">
            <h1>{translations?.personal_info?.personal_information}</h1>
            <VscOpenPreview
              onClick={handleShowCV}
              className="text-xl cursor-pointer"
            />
          </div>
          <hr className="text-black w-full" />
          <PhotoUpload />
          <form
            onSubmit={handleSubmit}
            className="bg-white w-[300px] md:w-[700px] rounded-md p-5 pb-0 pt-0 flex flex-col gap-2 "
          >
            <div className="flex flex-wrap justify-between gap-2">
              {Array.isArray(basicFormElements) &&
                basicFormElements.map((element, index) => (
                  <div className="w-full md:w-[290px]" key={index}>
                    <input
                      type="text"
                      placeholder={element.placeholder}
                      className="border-1 border-[#999898] w-full h-[30px] md:h-[40px] md:text-sm text-xs p-2 outline-none rounded-md"
                      name={element.name}
                      value={formData[element.name]}
                      onChange={handleChange}
                    />
                  </div>
                ))}
              {showAdditionalFields &&
                additionalFormElements.map((element, index) => (
                  <div className="w-full md:w-[290px]" key={index}>
                    <input
                      type="text"
                      placeholder={element.placeholder}
                      className="border-1 border-[#999898] w-full h-[30px] md:h-[40px] md:text-sm text-xs p-2 outline-none rounded-md"
                      name={element.name}
                      value={formData[element.name]}
                      onChange={handleChange}
                    />
                  </div>
                ))}
            </div>
            <button
              type="button"
              onClick={toggleAdditionalFields}
              className="text-[#6169cf] underline"
            >
              {showAdditionalFields
                ? translations?.personal_info?.hide_additional_fields
                : translations?.personal_info?.show_additional_fields}
            </button>
            <button
              type="submit"
              className="text-white bg-[#6169cf] p-3 rounded-md font-semibold hover:bg-[#373d94] outline-none border-none"
            >
              {translations?.personal_info?.next_step}
            </button>
          </form>
        </div>

        <span className="text-xs text-[#999898] text-center p-3 flex justify-center items-center">
          {translations?.personal_info?.disclaimer}
        </span>
      </div>
      {showCVModal ? (
        <div className="bg-black bg-opacity-75 absolute top-0 left-0 w-full min-h-screen flex justify-center items-center">
          <div ref={modalRef}>
            <CV />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default PersonalInfo;
