import React, { useCallback } from "react";
import { MdDelete, MdEdit } from "react-icons/md";
import useAxios from "../utils/useAxios";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../redux/reducers/authSlice";
import { useNavigate } from "react-router-dom";
import DeleteModal from "./modals/DeleteModal";
import { enqueueSnackbar } from "notistack";

const UserAccount = () => {
  const initialState = {
    fullname: "",
    email: "",
    userrole: "",
  };
  const [users, setUsers] = React.useState([]);
  const [edit, setEdit] = React.useState(null);
  const [formData, setFormData] = React.useState(initialState);
  const { userId } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  const axiosInstance = useAxios();

  const apiURL = process.env.REACT_APP_API_URL;

  const fetchUsers = useCallback(async () => {
    try {
      const { data } = await axiosInstance.get(`/users/${userId}`);
      setUsers(data);
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  }, [apiURL]);

  React.useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const handleDelete = (item) => async () => {
    try {
      await axiosInstance.delete(`/users/${item.id}`);
      fetchUsers();
      dispatch(logout());
      navigate("/");
      enqueueSnackbar("Account deleted", { variant: "success" });
    } catch (error) {
      console.log(error);
    }
  };

  const deleteModalShow = () => {
    setOpen(true);
  };

  const handleEdit = (row) => {
    setEdit(row);
    setFormData({
      ...row,
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axiosInstance.put(`/users/${edit.id}`, formData);
      fetchUsers();
      setEdit(null);
      setFormData(initialState);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="w-full h-screen flex flex-col justify-center items-center">
      <div className="relative overflow-x-auto rounded-md">
        <table className="w-full text-[12px] table-sm  text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                Full Name
              </th>
              <th scope="col" className="px-6 py-3">
                Email
              </th>
              <th scope="col" className="px-6 py-3">
                Role
              </th>
              <th scope="col" className="px-6 py-3">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
              <th
                scope="row"
                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                {users?.fullname}
              </th>
              <td className="px-6 py-4">{users?.email}</td>
              <td className="px-6 py-4">{users?.userrole}</td>

              <td>
                {users && (
                  <div className="px-6 py-4 flex items-center justify-center gap-3 ">
                    <MdDelete
                      className="text-red-500 cursor-pointer"
                      onClick={deleteModalShow}
                    />
                    <MdEdit
                      className="text-blue-500 cursor-pointer"
                      onClick={() => handleEdit(users)}
                    />
                  </div>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {edit && (
        <div className="absolute top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 flex justify-center items-center">
          <form onSubmit={handleSubmit} className="w-full max-w-sm">
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="fullname"
              >
                Username
              </label>
              <input
                id="fullname"
                name="fullname"
                type="text"
                value={formData.fullname}
                onChange={handleChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="email"
              >
                Email
              </label>
              <input
                id="email"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="mb-6">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="userrole"
              >
                Role
              </label>
              <input
                id="userrole"
                name="userrole"
                type="text"
                value={formData.userrole}
                onChange={handleChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="flex items-center justify-between">
              <button
                type="submit"
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Save
              </button>
            </div>
          </form>
        </div>
      )}
      {open && (
        <DeleteModal
          handleDelete={handleDelete}
          users={users}
          open={open}
          setOpen={setOpen}
        />
      )}
    </div>
  );
};

export default UserAccount;
