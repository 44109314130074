import * as React from "react";
import { createBrowserRouter } from "react-router-dom";
import "./index.css";
import MainLayout from "./layouts/MainLayout";
import Experiences from "./client-panel/cv-control/experiences/Experiences";
import Home from "./client-panel/cv-control/Home";
import ClientRouter from "./router/ClientRouter";
import Login from "./components/Login";
import Register from "./components/Register";
import AdminRouter from "./router/AdminRouter";
import Users from "./admin/Users";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";
import PersonalInfo from "./client-panel/cv-control/personalinfo/PersonalInfo";
import InterestsAndSkills from "./client-panel/cv-control/interests-skills/InterestsAndSkills";
import { CV } from "./client-panel/CV";
import UserAccount from "./components/UserAccount";

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "register",
        element: <Register />,
      },
      {
        path: "forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
      {
        element: <AdminRouter />,
        children: [
          {
            path: "users",
            element: <Users />,
          },
        ],
      },

      {
        element: <ClientRouter />, // Korunan rotalar için ClientRouter kullanıyoruz
        children: [
          {
            path: "personal-info",
            element: <PersonalInfo />,
          },
          {
            path: "interests-skills",
            element: <InterestsAndSkills />,
          },
          {
            path: "experiences",
            element: <Experiences />,
          },
          {
            path: "resume",
            element: <CV />,
          },
          {
            path: "user-account",
            element: <UserAccount />,
          },
        ],
      },
    ],
  },
]);

export default router;
