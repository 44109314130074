import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "../redux/reducers/authSlice";

const LanguageSelector = () => {
  const dispatch = useDispatch();
  const { language } = useSelector((state) => state.auth);

  const handleLanguageChange = (e) => {
    dispatch(setLanguage(e.target.value));
  };

  return (
    <select
      value={language}
      onChange={handleLanguageChange}
      className="bg-[#373d94] outline-none rounded-md"
    >
      <option value="en">EN</option>
      <option value="de">DE</option>
    </select>
  );
};

export default LanguageSelector;
