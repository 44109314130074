import React from "react";

const DeleteModal = ({ handleDelete, users, open, setOpen }) => {
  const handleCancel = () => {
    setOpen(false);
  };
  return (
    <div className="absolute top-0 left-0 w-100 h-full bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white w-50 text-center text-sm flex flex-col gap-2 p-3 rounded-md">
        <h1 className="text-red-500">Warning!</h1>
        <p>
          "If you proceed with the deletion, all related data in the system
          associated with you will be deleted. Do you confirm the deletion?"
        </p>
        <div className="flex justify-center gap-5">
          <button
            onClick={handleDelete(users)}
            className="bg-red-500 text-white px-3 py-1 rounded-md"
          >
            Yes
          </button>
          <button
            onClick={handleCancel}
            className="bg-green-500 text-white px-3 py-1 rounded-md"
          >
            No
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
