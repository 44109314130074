import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout, setDropdownMenu } from "../../redux/reducers/authSlice";
import { Link, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";

const NavbarDropdown = ({ translations }) => {
  const { token, userId, userrole } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const dropdownRef = useRef(null);

  const closeDropdown = () => {
    dispatch(setDropdownMenu(false));
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
    enqueueSnackbar("Logout successful", { variant: "success" });
    dispatch(setDropdownMenu(false));
  };

  const showAuthMenu = () => {
    dispatch(setDropdownMenu(false));
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeDropdown();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div
      ref={dropdownRef}
      className="w-[200px] absolute text-xs  bg-white right-0 flex flex-col items-center p-3 z-50 rounded-md mt-2"
    >
      <div className="absolute -top-2 right-0 w-0 h-0 border-l-[18px] border-l-transparent border-r-[18px] border-r-transparent border-b-[18px] border-b-white"></div>

      <ul className="text-center flex flex-col gap-2 text-[#999898]">
        <li
          className="hover:text-[#01EEFE] cursor-pointer"
          onClick={showAuthMenu}
        >
          <Link to="/login">{translations?.navbar?.sign_in}</Link>
        </li>
        <li
          className="hover:text-[#01EEFE] cursor-pointer"
          onClick={showAuthMenu}
        >
          <Link to="/register">{translations?.navbar?.sign_up}</Link>
        </li>

        <>
          <li>
            <Link
              to="/"
              className="hover:text-[#01EEFE] cursor-pointer"
              onClick={closeDropdown}
            >
              {translations?.navbar?.home}
            </Link>
          </li>
          {token && userId && (
            <div className="flex flex-col gap-2">
              <li>
                <Link
                  to="/personal-info"
                  className="hover:text-[#01EEFE] cursor-pointer"
                  onClick={closeDropdown}
                >
                  {translations?.navbar?.personal_info}
                </Link>
              </li>
              <li>
                <Link
                  to="/interests-skills"
                  className="hover:text-[#01EEFE] cursor-pointer"
                  onClick={closeDropdown}
                >
                  {translations?.navbar?.interests_skills}
                </Link>
              </li>
              <li>
                <Link
                  to="/experiences"
                  className="hover:text-[#01EEFE] cursor-pointer"
                  onClick={closeDropdown}
                >
                  {translations?.navbar?.experiences}
                </Link>
              </li>
              <li>
                <Link
                  to="/resume"
                  className="hover:text-[#01EEFE] cursor-pointer"
                  onClick={closeDropdown}
                >
                  {translations?.navbar?.resume}
                </Link>
              </li>
              {token && userId && userrole === "client" && (
                <li>
                  <Link
                    to="/user-account"
                    className="hover:text-[#01EEFE] cursor-pointer"
                    onClick={closeDropdown}
                  >
                    User Account
                  </Link>
                </li>
              )}
            </div>
          )}
        </>

        {token && userrole === "admin" && userId && (
          <li>
            <Link
              to="/users"
              className="hover:text-[#01EEFE] cursor-pointer"
              onClick={closeDropdown}
            >
              {translations?.navbar?.users}
            </Link>
          </li>
        )}

        {token && userId && (
          <li className="mt-2">
            <Link
              onClick={handleLogout}
              className="bg-[#01EEFE] w-[200px] h-[40px] p-2 rounded-md text-white hover:bg-[#373d94] cursor-pointer"
            >
              {translations?.navbar?.logout}
            </Link>
          </li>
        )}
      </ul>
    </div>
  );
};

export default NavbarDropdown;
