import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SaveBtn from "../../../components/buttons/SaveBtn";
import AddBtn from "../../../components/buttons/AddBtn";
import { MdDelete, MdEdit } from "react-icons/md";
import useAxios from "../../../utils/useAxios";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { useOutletContext } from "react-router-dom";

const Language = ({ onMoveUp, onMoveDown }) => {
  const { userId } = useSelector((state) => state.auth);
  const initialState = {
    userId: userId,
    language: "",
  };

  const [language, setLanguage] = useState([]);
  const axiosInstance = useAxios();
  const { translations } = useOutletContext();

  const getLanguage = useCallback(async () => {
    try {
      const { data } = await axiosInstance.get(`/language`);
      setLanguage(data);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    getLanguage();
  }, [getLanguage]);

  const [formData, setFormData] = useState(initialState);
  const [expand, setExpand] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [edit, setEdit] = useState(false);

  const formElements = [
    {
      name: "language",
      placeholder: translations?.languages?.language,
    },
  ];

  const handleExpand = () => {
    setExpand(!expand);
  };

  const handleChange = async (e) => {
    const newFormData = { ...formData, [e.target.name]: e.target.value };
    setFormData(newFormData);
  };

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      if (edit === false) {
        await axiosInstance.post(`/language`, formData);
      } else {
        await axiosInstance.put(`/language/${formData.id}`, formData);
        setEdit(false);
      }
      getLanguage();
      setFormData(initialState);
      setShowForm(false);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteLanguage = async (id) => {
    try {
      await axiosInstance.delete(`/language/${id}`);
      getLanguage();
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditBtn = (row, index) => {
    setEdit(index);
    setShowForm(true);
    setFormData(row);
  };

  return (
    <div className="bg-white w-[300px] md:w-[500px] mx-auto flex flex-col gap-3 p-4 rounded-md shadow">
      <div className="flex justify-between items-center">
        <h1 onClick={handleExpand} className="flex-1 cursor-pointer">
          {translations?.languages?.title}
        </h1>
        <div className="flex gap-2 text-xl">
          <IoIosArrowUp onClick={onMoveUp} className="cursor-pointer" />
          <IoIosArrowDown onClick={onMoveDown} className="cursor-pointer" />
        </div>
      </div>
      {expand ? (
        <>
          <hr />
          {language.map((data, index) => (
            <div key={index} className="flex flex-wrap gap-3">
              <div className="flex justify-between items-start gap-3 text-xs w-full">
                <div className="flex flex-col">
                  <div className="flex gap-1 w-96">
                    <p className="break-words w-full">{data.language}</p>
                  </div>
                </div>

                <div className="flex gap-3 w-12">
                  <MdDelete
                    className="rounded-sm cursor-pointer "
                    size={15}
                    onClick={() => deleteLanguage(data.id)}
                  />

                  <MdEdit
                    className="rounded-sm cursor-pointer"
                    size={15}
                    onClick={() => handleEditBtn(data, index)}
                  />
                </div>
              </div>
            </div>
          ))}

          <>
            {showForm ? (
              <form
                className="flex flex-wrap justify-between gap-3"
                onSubmit={handleSave}
              >
                {formElements.map((element, index) =>
                  element.name === "level" ? (
                    <select
                      key={index}
                      className="border-1  border-[#999898] w-[210px] h-[40px] p-2 outline-none rounded-md"
                      name={element.name}
                      value={formData[element.name]}
                      onChange={handleChange}
                    >
                      <option value="">Select Level</option>
                      <option value="Beginner">Beginner</option>
                      <option value="Elementary">Elementary</option>
                      <option value="Intermediate">Intermediate</option>
                      <option value="Upper Intermediate">
                        Upper Intermediate
                      </option>
                      <option value="Advanced">Advanced</option>
                      <option value="Fluent">Fluent</option>
                      <option value="Native">Native</option>
                    </select>
                  ) : (
                    <input
                      key={index}
                      type="text"
                      placeholder={element.placeholder}
                      className="border-1  border-[#999898] w-full h-[40px] p-2 outline-none rounded-md"
                      name={element.name}
                      value={formData[element.name]}
                      onChange={handleChange}
                    />
                  )
                )}

                <div className="flex justify-end w-full">
                  <SaveBtn />
                </div>
              </form>
            ) : null}
            <AddBtn setShowForm={setShowForm} />
          </>
        </>
      ) : null}
    </div>
  );
};

export default Language;
