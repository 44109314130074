import React, { useRef } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Download from "../components/buttons/Download";
import Left from "./cv-review/left/Left";
import Right from "./cv-review/right/Right";
import { useLocation, useOutletContext } from "react-router-dom";

export const CV = () => {
  const contentRef = useRef();
  const location = useLocation();
  const { translations } = useOutletContext();

  const generatePDF = () => {
    const input = contentRef.current;
    html2canvas(input, {
      scale: 5, // Scale'i artırarak kaliteyi iyileştirebilirsiniz
      useCORS: true,
      logging: true,
      allowTaint: false,
      backgroundColor: null,
    })
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4");

        const imgWidth = 210;
        const pageHeight = 295;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        let position = 0;

        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft > 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }

        pdf.save("download.pdf");
      })
      .catch((err) => {
        console.error("Error generating PDF: ", err);
      });
  };

  const isCV = location.pathname === "/resume";

  return (
    <div
      className={`flex flex-col gap-3 justify-center items-center pt-28 pb-28 scale-50 md:scale-75`}
    >
      {isCV ? (
        <h1 className="text-white">{translations?.resume?.your_cv_ready}</h1>
      ) : null}
      <div
        ref={contentRef}
        style={{ width: "210mm", minHeight: "295mm" }}
        className="grid grid-cols-7 bg-white shadow-lg "
      >
        <Left />
        <Right />
      </div>
      {isCV ? <Download generatePDF={generatePDF} /> : null}
    </div>
  );
};
