import React from "react";
import { useDispatch } from "react-redux";
import { login } from "../redux/reducers/authSlice";
import axios from "axios";
import { useSnackbar } from "notistack";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const Login = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { translations } = useOutletContext();

  const initialState = {
    email: "",
    password: "",
    rememberMe: false,
  };

  const [formData, setFormData] = React.useState(initialState);
  const [showPassword, setShowPassword] = React.useState(false);
  const navigate = useNavigate();

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(`${apiUrl}/login`, formData, {
        withCredentials: true,
      });
      setFormData(initialState);
      dispatch(login({ user: data.user, token: data.accessToken }));
      enqueueSnackbar("Login successful", { variant: "success" });
      navigate("/personal-info");
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Login failed", { variant: "error" });
    }
  };

  return (
    <div className="bg-[#20242D] w-full h-screen flex justify-center items-center">
      <div className="flex flex-col gap-3 items-center">
        <div className="w-[350px] text-white text-center">
          {translations?.login?.text}
        </div>
        <form
          onSubmit={handleSubmit}
          className="flex flex-col items-center gap-3"
        >
          <input
            name="email"
            value={formData.email}
            onChange={handleChange}
            type="email"
            placeholder={translations?.login?.email}
            className="ps-3 bg-[#444446] w-[350px] h-[40px] rounded-lg text-white outline-none placeholder:text-[#999898]"
          />
          <div className="relative w-[350px]">
            <input
              name="password"
              value={formData.password}
              onChange={handleChange}
              type={showPassword ? "text" : "password"}
              placeholder={translations?.login?.password}
              className="ps-3 bg-[#444446] w-full h-[40px] rounded-lg text-white outline-none placeholder:text-[#999898]"
            />
            <button
              type="button"
              onClick={handlePasswordVisibility}
              className="absolute inset-y-0 right-3 flex items-center text-[#01EEFE]"
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
          <div className="flex items-center w-[350px] justify-between">
            <label className="flex items-center text-[#999898]">
              <input
                type="checkbox"
                name="rememberMe"
                checked={formData.rememberMe}
                onChange={handleChange}
                className="mr-2"
              />
              {translations?.login?.remember_me}
            </label>
            <Link className="text-[#01EEFE] text-sm" to="/forgot-password">
              {translations?.login?.forgot_password}
            </Link>
          </div>
          <button
            type="submit"
            className="w-[350px] h-[40px] border-2 border-[#01EEFE] text-[#01EEFE] rounded-lg hover:bg-[#01EEFE] hover:text-white"
          >
            {translations?.login?.sign_in}
          </button>
        </form>
        <div className="text-sm flex gap-3">
          <span className="text-[#999898]">
            {translations?.login?.no_account}
          </span>
          <Link className="text-[#01EEFE] cursor-pointer" to="/register">
            {translations?.login?.sign_up}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
