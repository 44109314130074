import React, { useState } from "react";
import axios from "axios";
import { useSnackbar } from "notistack";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { MdDone } from "react-icons/md";
import { IoClose } from "react-icons/io5";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const ResetPassword = () => {
  const [formData, setFormData] = useState({
    password: "",
  });
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token"); // Token'ı URL'den alıyoruz
  const navigate = useNavigate();
  const [passwordCriteria, setPasswordCriteria] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    specialChar: false,
  });
  const [showCriteria, setShowCriteria] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });

    if (e.target.name === "password") {
      const { value } = e.target;
      const criteria = {
        length: value.length >= 8,
        uppercase: /[A-Z]/.test(value),
        lowercase: /[a-z]/.test(value),
        number: /[0-9]/.test(value),
        specialChar: /[@$!%*?&-]/.test(value),
      };
      setPasswordCriteria(criteria);
      setShowCriteria(
        value.length > 0 && !Object.values(criteria).every(Boolean)
      );
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/reset-password`, {
        password: formData.password,
        token,
      });
      enqueueSnackbar("Password has been reset", { variant: "success" });
      navigate("/login");
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Failed to reset password", { variant: "error" });
    }
  };

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="bg-[#20242D] w-full h-screen flex justify-center items-center">
      <div className="flex flex-col gap-3 items-center">
        <div className="w-[350px] text-white text-center">
          Enter your new password
        </div>
        <form
          onSubmit={handleSubmit}
          className="flex flex-col items-center gap-3"
        >
          <div className="relative w-[350px]">
            <input
              name="password"
              value={formData.password}
              onChange={handleChange}
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              className="ps-3 bg-[#444446] w-full h-[40px] rounded-lg text-white outline-none placeholder:text-[#999898]"
            />
            <button
              type="button"
              onClick={handlePasswordVisibility}
              className="absolute inset-y-0 right-3 flex items-center text-[#01EEFE]"
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
          {showCriteria && (
            <div className="relative">
              <div className="absolute -top-2 w-0 h-0 border-l-[18px] border-l-transparent border-r-[18px] border-r-transparent border-b-[18px] border-b-white"></div>
              <div className="text-xs bg-white rounded-md p-2 text-left w-[350px] text-[#999898]">
                <div
                  style={{ color: passwordCriteria.length ? "green" : "red" }}
                  className="flex items-center gap-1"
                >
                  {passwordCriteria.length ? <MdDone /> : <IoClose />}
                  At least 8 characters
                </div>
                <div
                  style={{
                    color: passwordCriteria.uppercase ? "green" : "red",
                  }}
                  className="flex items-center gap-1"
                >
                  {passwordCriteria.uppercase ? <MdDone /> : <IoClose />}
                  At least one uppercase letter
                </div>
                <div
                  style={{
                    color: passwordCriteria.lowercase ? "green" : "red",
                  }}
                  className="flex items-center gap-1"
                >
                  {passwordCriteria.lowercase ? <MdDone /> : <IoClose />}
                  At least one lowercase letter
                </div>
                <div
                  style={{ color: passwordCriteria.number ? "green" : "red" }}
                  className="flex items-center gap-1"
                >
                  {passwordCriteria.number ? <MdDone /> : <IoClose />}
                  At least one number
                </div>
                <div
                  style={{
                    color: passwordCriteria.specialChar ? "green" : "red",
                  }}
                  className="flex items-center gap-1"
                >
                  {passwordCriteria.specialChar ? <MdDone /> : <IoClose />}
                  At least one special character (@, $, !, %, *, ?, &, -)
                </div>
              </div>
            </div>
          )}
          <button
            type="submit"
            className="w-[350px] h-[40px] border-2 border-[#01EEFE] text-[#01EEFE] rounded-lg hover:bg-[#01EEFE] hover:text-white"
          >
            Reset Password
          </button>
        </form>
        <div className="text-sm flex gap-3">
          <span className="text-[#999898]">Remembered your password?</span>
          <Link className="text-[#01EEFE] cursor-pointer" to="/login">
            Login
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
