import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import useAxios from "../utils/useAxios";

const AdminRouter = () => {
  const { userId, token, userrole, language } = useSelector(
    (state) => state.auth
  );
  const [translations, setTranslations] = useState(null);
  const axiosInstance = useAxios();

  const fetchTranslations = useCallback(async () => {
    try {
      const { data } = await axiosInstance.get(`/language/${language}`);
      setTranslations(data);
    } catch (error) {
      console.error("Error fetching translations: ", error);
    }
  }, [axiosInstance, language]);

  useEffect(() => {
    fetchTranslations();
  }, [fetchTranslations]);

  if (!translations) {
    return <div>Loading...</div>;
  }

  return userId && token && userrole === "admin" ? (
    <Outlet context={{ translations }} />
  ) : (
    <Navigate to="/login" />
  );
};

export default AdminRouter;
