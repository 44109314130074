import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import useAxios from "../../../utils/useAxios";

const Image = () => {
  const { userId } = useSelector((state) => state.auth); // Redux store'dan userId'yi al
  const [images, setImages] = useState([]);
  const axiosInstance = useAxios();
  const apiURL = process.env.REACT_APP_FILE_API_URL;

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const { data } = await axiosInstance.get(`/images/${userId}`);
        setImages(data);
      } catch (err) {
        console.error(err);
      }
    };

    fetchImages();
  }, []);

  return (
    <div className="pt-3">
      {images.map((image) => (
        <div key={image.id} className="image-item">
          <img
            src={`${apiURL}${image.path}`} // Tam URL'yi belirtiyoruz
            alt={`Uploaded by user ${userId}`}
            className="object-cover rounded-full h-[200px] w-[200px]"
          />
        </div>
      ))}
    </div>
  );
};

export default Image;
