import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Links = ({ translations }) => {
  const { token, userrole, userId } = useSelector((state) => state.auth);
  return (
    <div className="w-full absolute left-0 right-0 d-none d-md-flex  text-sm justify-center gap-x-5 gap-y-2 flex-wrap">
      <div className="flex justify-center items-center gap-x-5  flex-wrap ">
        {token && userId && (
          <div className="flex justify-center items-center gap-x-5  flex-wrap ">
            <Link to="/" className="cursor-pointer hover:text-[#01EEFE] ">
              {translations?.navbar?.home}
            </Link>
            <Link
              to="/personal-info"
              className="cursor-pointer hover:text-[#01EEFE]"
            >
              {translations?.navbar?.personal_info}
            </Link>
            <Link
              to="/interests-skills"
              className="cursor-pointer hover:text-[#01EEFE]"
            >
              {translations?.navbar?.interests_skills}
            </Link>
            <Link
              to="/experiences"
              className="cursor-pointer hover:text-[#01EEFE]"
            >
              {translations?.navbar?.experiences}
            </Link>
            <Link to="/resume" className="cursor-pointer hover:text-[#01EEFE]">
              {translations?.navbar?.resume}
            </Link>
            {token && userId && userrole === "client" && (
              <Link
                to="/user-account"
                className="cursor-pointer hover:text-[#01EEFE]"
              >
                User Account
              </Link>
            )}
          </div>
        )}

        {token && userId && userrole === "admin" && (
          <Link to="/users" className="cursor-pointer hover:text-[#01EEFE]">
            {translations?.navbar?.users}
          </Link>
        )}
      </div>
    </div>
  );
};

export default Links;
