import React from "react";
import useAxios from "../../../utils/useAxios";
import { useOutletContext } from "react-router-dom";

const Education = () => {
  const [education, setEducation] = React.useState([]);
  const axiosInstance = useAxios();
  const { translations } = useOutletContext();

  const fetchEducation = React.useCallback(async () => {
    try {
      const { data } = await axiosInstance.get(`/education`);

      setEducation(data);
    } catch (error) {
      console.log(error);
    }
  }, []);

  React.useEffect(() => {
    fetchEducation();
  }, [fetchEducation]);

  return education.length > 0 ? (
    <div>
      <hr className="my-1"></hr>
      <div className="flex flex-col">
        <h2 className="text-lg font-semibold mb-3">
          {translations?.education?.review_title}
        </h2>
        <div className="flex flex-col gap-3">
          {education.map((item, index) => (
            <div key={index} className="text-xs flex w-full gap-3">
              <div className="w-40 flex flex-wrap">
                <span className="break-words"> {item.startDate}</span>
                {item.startDate && item.endDate && (
                  <span className="mx-1">-</span>
                )}
                <span className="break-words">{item.endDate}</span>
              </div>
              <div className="w-96 flex flex-col gap-1">
                <h2 className="text-md font-semibold leading-none break-words">
                  {item.schoolName}
                </h2>
                <h3 className="break-words">{item.department}</h3>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  ) : null;
};

export default Education;
