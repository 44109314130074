import React from "react";
import Image from "./Image";
import Skills from "./Skills";
import Hobbies from "./Hobbies";
import Language from "./Language";
import PersonalData from "./PersonalData";

const Left = () => {
  return (
    <div className="bg-slate-700 col-span-2 text-white relative">
      <div className="flex flex-col gap-3 w-[200px] mx-auto">
        <Image />
        <PersonalData />
        <Language />
        <Skills />
        <Hobbies />
      </div>
    </div>
  );
};

export default Left;
