import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import useAxios from "../../../utils/useAxios";
import { useOutletContext } from "react-router-dom";

const PersonalData = () => {
  const { userId } = useSelector((state) => state.auth);
  const [personalData, setPersonalData] = React.useState([]);
  const axiosInstance = useAxios();
  const { translations } = useOutletContext();

  const fetchPersonalData = useCallback(async () => {
    try {
      const { data } = await axiosInstance.get(`/personalinfo`);
      setPersonalData(data);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const modifiedData = personalData
    .filter((item) => item.userId === userId)
    .map((item) => {
      return {
        ...item,
        dateofbirth: item.dateofbirth?.split("T")[0],
      };
    });

  React.useEffect(() => {
    fetchPersonalData();
  }, [fetchPersonalData]);

  const hasData = modifiedData.some((item) => {
    return (
      item.name ||
      item.surname ||
      item.email ||
      item.phone ||
      item.street ||
      item.postalCode ||
      item.city ||
      item.country ||
      item.driversLicense ||
      item.maritalStatus ||
      item.dateofbirth ||
      item.placeofbirth
    );
  });

  return hasData ? (
    <div className="flex flex-col gap-1 text-xs ">
      <h2 className="text-lg ">{translations?.personal_info?.review_title}</h2>
      {modifiedData.map((item, index) => {
        return (
          <div key={index} className="flex flex-col gap-1">
            {item.name || item.surname ? (
              <div className="flex flex-col ">
                <p className="font-semibold">
                  {translations?.personal_info?.fullname}
                </p>
                <p className="break-words w-full">
                  {item.name} {item.surname}
                </p>
              </div>
            ) : null}
            {item.email ? (
              <div className="flex flex-col ">
                <p className="font-semibold">
                  {translations?.personal_info?.email}
                </p>
                <p className="break-words w-full">{item.email}</p>
              </div>
            ) : null}
            {item.phone ? (
              <div className="flex flex-col ">
                <p className="font-semibold">
                  {translations?.personal_info?.phone}
                </p>
                <p className="break-words w-full">{item.phone}</p>
              </div>
            ) : null}
            {item.street || item.postalCode || item.city || item.country ? (
              <div className="flex flex-col ">
                <p className="font-semibold">
                  {translations?.personal_info?.address}
                </p>
                <div>
                  <p className="break-words">{item.street}</p>
                  <p className="flex gap-3 break-words w-full">
                    {item.postalCode} {item.city}
                  </p>
                </div>
              </div>
            ) : null}

            {item.driversLicense ? (
              <div className="flex flex-col ">
                <p className="font-semibold">
                  {translations?.personal_info?.driver_license}
                </p>
                <p className="break-words w-full">{item.driversLicense}</p>
              </div>
            ) : null}
            {item.maritalStatus ? (
              <div className="flex flex-col ">
                <p className="font-semibold">
                  {translations?.personal_info?.marital_status}
                </p>
                <p className="break-words w-full">{item.maritalStatus}</p>
              </div>
            ) : null}
            {item.dateofbirth ? (
              <div className="flex flex-col ">
                <p className="font-semibold">
                  {translations?.personal_info?.date_of_birth}
                </p>
                <p className="break-words w-full">{item.dateofbirth}</p>
              </div>
            ) : null}
            {item.placeofbirth ? (
              <div className="flex flex-col ">
                <p className="font-semibold">
                  {translations?.personal_info?.place_of_birth}
                </p>
                <p className="break-words w-full">{item.placeofbirth}</p>
              </div>
            ) : null}
          </div>
        );
      })}
    </div>
  ) : null;
};

export default PersonalData;
