import React, { useCallback, useEffect, useState } from "react";
import useAxios from "../../../utils/useAxios";
import { useOutletContext } from "react-router-dom";

const Hobbies = () => {
  const [hobbies, setHobbies] = useState("");
  const axiosInstance = useAxios();
  const { translations } = useOutletContext();

  const fetchHobbies = useCallback(async () => {
    try {
      const { data } = await axiosInstance.get(`/hobbies`);
      setHobbies(data);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    fetchHobbies();
  }, [fetchHobbies]);

  return hobbies.length > 0 ? (
    <div className="flex flex-col text-xs ">
      <h2 className="text-lg ">{translations?.hobbies?.review_title}</h2>

      {Array.isArray(hobbies) &&
        hobbies.map((item, index) => (
          <div key={index} className="flex flex-col gap-1">
            <p className="break-words w-full">{item.hobby}</p>
          </div>
        ))}
    </div>
  ) : null;
};

export default Hobbies;
