import axios from "axios";
import { useSelector } from "react-redux";
import { useMemo } from "react";

const apiURL = process.env.REACT_APP_API_URL;

const useAxios = () => {
  const { token } = useSelector((state) => state.auth);

  const axiosInstance = useMemo(() => {
    const instance = axios.create({
      baseURL: apiURL,
      withCredentials: true,
    });

    instance.interceptors.request.use(
      async (config) => {
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    instance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    return instance;
  }, [token]); // Token değiştiğinde yeni instance oluşturulacak

  return axiosInstance;
};

export default useAxios;
