import React, { useCallback } from "react";
import useAxios from "../../../utils/useAxios";
import { useOutletContext } from "react-router-dom";

const WorkExperience = () => {
  const [workExperience, setWorkExperience] = React.useState([]);
  const axiosInstance = useAxios();
  const { translations } = useOutletContext();

  const fetchWorkExperience = useCallback(async () => {
    try {
      const { data } = await axiosInstance.get(`/workexperience`);

      setWorkExperience(data);
    } catch (error) {
      console.log(error);
    }
  }, []);

  React.useEffect(() => {
    fetchWorkExperience();
  }, [fetchWorkExperience]);

  return workExperience.length > 0 ? (
    <div>
      <hr className="my-1"></hr>

      <div className="flex flex-col gap-2">
        <h2 className="text-lg font-semibold">
          {translations?.work_experience?.review_title}
        </h2>

        {workExperience.map((item, index) => (
          <div key={index} className="text-xs flex w-full gap-3">
            <div className="w-40 flex flex-wrap">
              <span className="break-words">{item.startDate}</span>
              {item.startDate && item.endDate && (
                <span className="mx-1">-</span>
              )}
              <span className="break-words">{item.endDate}</span>
            </div>

            <div className="w-96 flex flex-col gap-1">
              <h2 className="text-md font-semibold break-words w-full">
                {item.companyName}
              </h2>
              <h3 className="break-words w-full">{item.position}</h3>
              <div className="mt-1 break-words w-full">{item.description}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  ) : null;
};

export default WorkExperience;
