import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  userId: null,
  email: null,
  username: null,
  userrole: null,
  token: null,
  showLogin: true,
  showAuthSection: false,
  adminPanel: false,
  dropdownMenu: false,
  language: "en",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    register: (state, action) => {
      const { user, token } = action.payload;
      state.user = user;
      state.userId = user.id;
      state.email = user.email;
      state.username = user.username;
      state.userrole = user.userrole;
      state.token = token;
    },
    login: (state, action) => {
      const { user, token } = action.payload;
      state.user = user;
      state.userId = user.id;
      state.email = user.email;
      state.username = user.username;
      state.userrole = user.userrole;
      state.token = token;
    },
    logout: (state) => {
      state.user = null;
      state.userId = null;
      state.email = null;
      state.username = null;
      state.userrole = null;
      state.token = null;
    },
    setShowLogin: (state, action) => {
      state.showLogin = action.payload;
    },
    setShowAuthSection: (state, action) => {
      state.showAuthSection = action.payload;
    },
    setAdminPanel: (state, action) => {
      state.adminPanel = action.payload;
    },
    setDropdownMenu: (state, action) => {
      state.dropdownMenu = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
  },
});

export const {
  register,
  login,
  logout,
  setShowLogin,
  setShowAuthSection,
  setAdminPanel,
  setDropdownMenu,
  setToken,
  setLanguage,
} = authSlice.actions;

export default authSlice.reducer;
