import React, { useCallback, useEffect, useState } from "react";
import useAxios from "../../../utils/useAxios";
import { useOutletContext } from "react-router-dom";

const Language = () => {
  const [language, setLanguage] = useState([]);
  const axiosInstance = useAxios();
  const { translations } = useOutletContext();

  const fetchLanguages = useCallback(async () => {
    try {
      const { data } = await axiosInstance.get(`/language`);
      setLanguage(data);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    fetchLanguages();
  }, [fetchLanguages]);

  return language.length > 0 ? (
    <div className="flex flex-col text-xs ">
      <h2 className="text-lg ">{translations?.languages?.review_title}</h2>

      {Array.isArray(language) &&
        language.map((item, index) => (
          <div key={index} className="flex justify-between">
            <span className="break-words w-full">{item.language}</span>
          </div>
        ))}
    </div>
  ) : null;
};

export default Language;
