import React, { useState } from "react";
import axios from "axios";
import { useSnackbar } from "notistack";
import { Link, useOutletContext } from "react-router-dom";
import RegisterModal from "./RegisterModal";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { MdDone } from "react-icons/md";
import { IoClose } from "react-icons/io5";

const Register = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { enqueueSnackbar } = useSnackbar();
  const { translations } = useOutletContext();

  const initialState = {
    userrole: "client",
    fullname: "",
    email: "",
    password: "",
  };

  const [show, setShow] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState(initialState);
  const [email, setEmail] = useState("");
  const [passwordCriteria, setPasswordCriteria] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    specialChar: false,
  });
  const [showCriteria, setShowCriteria] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });

    if (e.target.name === "password") {
      const { value } = e.target;
      const criteria = {
        length: value.length >= 8,
        uppercase: /[A-Z]/.test(value),
        lowercase: /[a-z]/.test(value),
        number: /[0-9]/.test(value),
        specialChar: /[@$!%*?&-]/.test(value),
      };
      setPasswordCriteria(criteria);
      setShowCriteria(
        value.length > 0 && !Object.values(criteria).every(Boolean)
      );
    }
  };

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Parola kriterlerini kontrol et
    const { length, uppercase, lowercase, number, specialChar } =
      passwordCriteria;
    if (!length || !uppercase || !lowercase || !number || !specialChar) {
      enqueueSnackbar("Password does not meet all criteria.", {
        variant: "error",
      });
      return;
    }

    try {
      await axios.post(`${apiUrl}/register`, formData);
      setEmail(formData.email);
      setFormData(initialState);
      enqueueSnackbar("Registration successful", { variant: "success" });
      setShow(true);
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Registration failed", { variant: "error" });
    }
  };

  return (
    <div className="bg-[#20242D] w-full h-screen flex justify-center items-center">
      <div className="flex flex-col gap-3 items-center">
        <div className="w-[350px] text-white text-center">
          {translations?.register?.text}
        </div>
        <form
          onSubmit={handleSubmit}
          className="flex flex-col justify-center items-center gap-3"
        >
          <input
            name="fullname"
            value={formData.fullname}
            onChange={handleChange}
            type="text"
            placeholder={translations?.register?.full_name}
            className="ps-3 bg-[#444446] w-[350px] h-[40px] rounded-lg text-white outline-none placeholder:text-[#999898]"
          />
          <input
            name="email"
            value={formData.email}
            onChange={handleChange}
            type="email"
            placeholder={translations?.register?.email}
            className="ps-3 bg-[#444446] w-[350px] h-[40px] rounded-lg text-white outline-none placeholder:text-[#999898]"
          />
          <div className="relative w-[350px]">
            <input
              name="password"
              value={formData.password}
              onChange={handleChange}
              type={showPassword ? "text" : "password"}
              placeholder={translations?.register?.password}
              className="ps-3 bg-[#444446] w-full h-[40px] rounded-lg text-white outline-none placeholder:text-[#999898]"
            />
            <button
              type="button"
              onClick={handlePasswordVisibility}
              className="absolute inset-y-0 right-3 flex items-center text-[#01EEFE]"
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
          {showCriteria && (
            <div className="relative">
              <div className="absolute -top-2 w-0 h-0 border-l-[18px] border-l-transparent border-r-[18px] border-r-transparent border-b-[18px] border-b-white"></div>
              <div className="text-xs bg-white rounded-md p-2 text-left w-[350px] text-[#999898]">
                <div
                  style={{ color: passwordCriteria.length ? "green" : "red" }}
                  className="flex items-center gap-1"
                >
                  {passwordCriteria.length ? <MdDone /> : <IoClose />}
                  At least 8 characters
                </div>
                <div
                  style={{
                    color: passwordCriteria.uppercase ? "green" : "red",
                  }}
                  className="flex items-center gap-1"
                >
                  {passwordCriteria.uppercase ? <MdDone /> : <IoClose />}
                  At least one uppercase letter
                </div>
                <div
                  style={{
                    color: passwordCriteria.lowercase ? "green" : "red",
                  }}
                  className="flex items-center gap-1"
                >
                  {passwordCriteria.lowercase ? <MdDone /> : <IoClose />}
                  At least one lowercase letter
                </div>
                <div
                  style={{ color: passwordCriteria.number ? "green" : "red" }}
                  className="flex items-center gap-1"
                >
                  {passwordCriteria.number ? <MdDone /> : <IoClose />}
                  At least one number
                </div>
                <div
                  style={{
                    color: passwordCriteria.specialChar ? "green" : "red",
                  }}
                  className="flex items-center gap-1"
                >
                  {passwordCriteria.specialChar ? <MdDone /> : <IoClose />}
                  At least one special character (@, $, !, %, *, ?, &, -)
                </div>
              </div>
            </div>
          )}
          <button
            type="submit"
            className="text-[#01EEFE] border-2 border-[#01EEFE] w-[350px] h-[40px] rounded-lg hover:bg-[#01EEFE] hover:text-white"
          >
            {translations?.register?.sign_up}
          </button>
          <div className="text-sm flex gap-3">
            <span className="text-[#999898]">
              {translations?.register?.already_account}
            </span>
            <Link className="text-[#01EEFE] cursor-pointer" to="/login">
              {translations?.register?.sign_in}
            </Link>
          </div>
        </form>
      </div>
      <RegisterModal show={show} setShow={setShow} email={email} />
    </div>
  );
};

export default Register;
