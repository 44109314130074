import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDropdownMenu } from "../../redux/reducers/authSlice";
import NavbarDropdown from "./NavbarDropdown";
import Links from "./Links";
import Logo from "./Logo";
import { CgMenuGridR } from "react-icons/cg";
import LanguageSelector from "../LanguageSelector";

const Navbar = ({ translations }) => {
  const { dropdownMenu } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const openDropDownMenu = () => {
    dispatch(setDropdownMenu(true));
  };

  return (
    <nav className="absolute w-full flex justify-between items-center text-white mx-auto mt-3 px-3">
      <div className="flex items-center justify-between w-full">
        <Logo />

        <Links translations={translations} />
        <div className="relative flex items-center gap-3">
          <LanguageSelector />
          <div>
            <CgMenuGridR
              className="text-3xl cursor-pointer hover:text-[#01EEFE] rounded-full"
              onClick={openDropDownMenu}
              translations={translations}
            />
            {dropdownMenu ? (
              <NavbarDropdown translations={translations} />
            ) : null}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
