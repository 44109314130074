import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../components/navbar/Navbar";
import { useSelector } from "react-redux";
import useAxios from "../utils/useAxios";

const MainLayout = () => {
  const { language } = useSelector((state) => state.auth);
  const [translations, setTranslations] = useState({});
  const axiosInstance = useAxios();

  useEffect(() => {
    const fetchTranslations = async () => {
      try {
        const { data } = await axiosInstance.get(`/language/${language}`);
        setTranslations(data);
      } catch (error) {
        console.error("Error fetching translations: ", error);
      }
    };
    fetchTranslations();
  }, [language]);

  if (!translations) {
    return <div>Loading...</div>;
  }

  return (
    <div className="relative min-h-screen w-full bg-[#20242D]">
      <Navbar translations={translations} />
      <Outlet context={{ translations }} />
    </div>
  );
};

export default MainLayout;
